@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,400;0,500;0,700;1,200&family=Montserrat:wght@100;400;600&display=swap');

body {
  background-color: #212121;
}

html,
body {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #c8c8c8;
}

p {
  color: #c8c8c8;
  font-size: 1.2rem;
  line-height: 1.9rem;
}

a {
  color: #c8c8c8;
  text-decoration: underline;
  transition: filter ease-in-out 0.25s;
}

a:hover {
  filter: brightness(75%);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.slide-item p {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: right;
}

/* Slide controls */
.react-slideshow-container .nav:first-of-type {
  left: 15px;
}

.react-slideshow-container .nav:last-of-type {
  right: 15px;
}
